import React from 'react';
import { Helmet } from 'react-helmet';
import config from '../../../data/SiteConfig';
import 'normalize.css';
import './globals.scss';
import styles from './main-layout.module.scss';

import Footer from '../Footer/footer';

export default function MainLayout({ children }) {
  return (
    <div className={styles.mainLayout}>
      <Helmet>
        <meta name="description" content={config.siteDescription} />
        <html lang="en" />
        <link rel="icon" href="/logos/logo-48.png" />
      </Helmet>
      {children}
      <Footer config={config} />
    </div>
  );
}
