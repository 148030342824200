import React from 'react';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { useIntl } from 'react-intl';
import styles from './footer.module.scss';

export default function Footer({ config }) {
  const intl = useIntl();
  const { copyright } = config;
  if (!copyright) {
    return null;
  }
  return (
    <footer className={styles.footer}>
      <div style={{color: '#fff'}}>{copyright}</div>
      <div className={styles.linksWrapper}>
        <Link className={styles.link} to={"/get-quote/address"}>{intl.formatMessage({ id: "footerQuote" })}</Link>
        <div className={styles.divider} />
        <Link className={styles.link} to={"/imprint"}>{intl.formatMessage({ id: "footerImprint" })}</Link>
        <div className={styles.divider} />
        <Link className={styles.link} to={"/privacy"}>{intl.formatMessage({ id: "footerPrivacy" })}</Link>
      </div>
    </footer>
  );
}
