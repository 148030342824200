const config = {
  siteTitle: "Nicholas Braat", // Site title.
  siteTitleShort: "NB Website", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Mietkoch München Nicholas Braat", // Alternative site title for SEO.
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://nick-braat.netlify.app", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "Nick Braat, your michelin star trained personal chef for hire in the Munich area. Get a quote now!", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteRssTitle: "Nick Braat Personal Cheff RSS Feed", // Title of the RSS feed
  googleAnalyticsID: "G-3P71FZDRS0", // GA tracking ID.
  disqusShortname: "https-fancy-fitness-eats", // Disqus shortname. // TODO set up disqus
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  postsPerPage: 0, // Amount of posts displayed per listing page.
  userName: "Nicholas Braat", // Username to display in the author segment.
  userEmail: "nicholasbraat@gmail.com", // Email used for RSS feed's author segment
  userLocation: "Munich, Germany", // User location to display in the author segment.
  userAvatar: "https://api.adorable.io/avatars/150/test.png", // User avatar to display in the author segment.
  userDescription:
      "Nick is a great chef...", // User description to display in the author segment. // TODO change description
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "Instagram",
      url: "https://www.instagram.com/braatnicholas",
      iconClassName: "fa fa-github" // TODO change this to instagram icon
    },
    {
      label: "Twitter",
      url: "https://twitter.com/",
      iconClassName: "fa fa-twitter"
    },
    {
      label: "Email",
      url: "mailto:new.email@gmail.com", // TODO change email
      iconClassName: "fa fa-envelope"
    }
  ],
  copyright: "Copyright © 2021. Nicholas Braat", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#333333", // Used for setting manifest and progress theme colors.
  backgroundColor: "#e0e0e0" // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
